@tailwind base;
@tailwind components;
@tailwind utilities;

body {font-family:'Pretendard'}

@font-face {
	font-family: 'Pretendard';
	font-weight: 900;
	font-display: swap;
	src: local('Pretendard Black'), url('../assets/fonts/Pretendard-Black.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 800;
	font-display: swap;
	src: local('Pretendard ExtraBold'), url('../assets/fonts/Pretendard-ExtraBold.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('../assets/fonts/Pretendard-Bold.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	font-display: swap;
	src: local('Pretendard SemiBold'), url('../assets/fonts/Pretendard-SemiBold.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('../assets/fonts/Pretendard-Medium.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('../assets/fonts/Pretendard-Regular.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 300;
	font-display: swap;
	src: local('Pretendard Light'), url('../assets/fonts/Pretendard-Light.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 200;
	font-display: swap;
	src: local('Pretendard ExtraLight'), url('../assets/fonts/Pretendard-ExtraLight.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 100;
	font-display: swap;
	src: local('Pretendard Thin'), url('../assets/fonts/Pretendard-Thin.subset.woff') format('woff');
}




  /* input style/ */
.react-datepicker__input-container input {
	/* width: 82px;
	height: 19px;
	padding: 5px 10px;
	background: #f5f5f5;
	border: 1px solid white;
	border-radius: 5px;
	font-size: 15px;
	font-weight: 400;
	text-align: center;
	color: #2b2b2b; */
}
  /* 
  .react-datepicker__triangle {
	display: none;
  }
  
  .react-datepicker-wrapper {
	width: 102px;
  }

   */

   /* .react-datepicker-ignore-onclickoutside {
	border: 1px solid #6400ff !important;
	outline: none;
  } */

.react-datepicker {
	/* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25); */

	width: 247px;
	height: 330px;
	background-color: white;
	border-color: #CCCCCC;
	border-width: 1px;
	border-radius: 4px;

	margin-top: 1px;
	margin-bottom: 1px;
}

  /* 요일들 */
.react-datepicker__day-names {
	display: grid;
	grid-template-columns: 35px 35px 35px 35px 35px 35px 35px;
	grid-template-rows: 35px;
	/* background-color: aqua;
	justify-content: center; */
	align-items: center;
	/* justify-content: space-between; */
	/* margin-top: 8px; */
	/* margin-left: 4px;
	margin-right: 4px; */
	/* background-color: rebeccapurple; */
	/* margin-top: 8px;
	margin-bottom: 8px;
	margin-left: 4px;
	margin-right: 4px; */
}

/* 달전체 */
.react-datepicker__month {
	/* display: flex;
	flex-direction: column; */
	/* margin-bottom: 10px; */
	/* justify-content: space-between; */
	/* text-align: center; */

	/* height: 97px; */
	/* margin-top: 25px !important; */
}

/* 날짜wrapper */
.react-datepicker__week {
	display: grid;
	grid-template-columns: 35px 35px 35px 35px 35px 35px 35px;
	grid-template-rows: 35px;
	align-items: center;
	/* display: flex; */
	/* justify-content: space-between; */
	/* flex-direction: row; */
	/* background-color: aqua; */
	/* justify-content: space-around; */
	/* align-items: center; */
	/* text-align: center; */
	/* align-items: center; */
	/* height: 14px; */
	/* margin: 8px 4px 8px 4px; */
  }


/* 각날짜들 */
.react-datepicker__day-name,
.react-datepicker__day {
	
	display: flex;
	justify-content: center;
	/* text-align: center; */
	/* background-color: yellow; */
  	/* width: 30px;
	height: 39px; */
	/* margin: 2px 4px 2px 4px; */
	color: #777777;
	cursor: pointer;

	font-size: 14px;
  /* 
  line-height: 14px !important; */
  /* color: #7a7a7a !important; */
}

/* 오늘날짜 */
.react-datepicker__day--today {
	background-color: white;
	font-weight: 500;
	color: #313131;
}

/* 선택한 날짜  */
.react-datepicker__day--selected {
	/* display: flex; */
	/* flex-direction: row; */
	/* justify-content: center;
	*/
	/* width: 30px;
	height: 39px; */
	/* background-color: white !important;
	border-radius: 7px !important;
	line-height: 14px; */
	align-items: center; 
	height: 35px;
	background-color: #313131;
	color: white;
	/* border-radius: 100px; */
}


  /* .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
	background-color: #6400ff !important;
	border-radius: 7px !important;
	color: white !important;
  } */

  /* .react-datepicker__header {
	height: 36px !important;
	padding: 0 !important;
	background-color: #6400ff !important;
	border: none !important;
	position: relative;
	text-align: center;
  }

 

  .custom-react-datepicker__select-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 198px;
	height: 36px;
  }
  .custom-react-datepicker__select-wrapper button {
	background-color: transparent;
	border: none;
  }
  .custom-react-datepicker__select-item {
	display: flex;
	font-display: row;
	justify-content: space-between;
	align-items: center;
	width: 64px;
  }
  .custom-react-datepicker__select-wrapper select {
	width: 54px;
	height: 18px;
	background: #ffffff;
	border-radius: 3px;
	font-weight: 400;
	font-size: 12px;
  }
  .custom-react-datepicker__select-wrapper span {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 18px;
	margin-left: 3px;
	font-weight: 500;
	font-size: 8px;
	color: white;
  }

 
 
  
 
  .react-datepicker__day--keyboard-selected {
	background-color: white !important;
	color: #2b2b2b !important;
  }
  
  .react-datepicker__day--range-start {
	background-color: #6400ff !important;
	border-radius: 7px !important;
	color: white !important;
  }
  .custom-react-datepicker__split-span {
	display: flex;
	font-display: row;
	justify-content: center;
	align-items: center;
	font-size: 15px;
	width: 11px;
	margin: 0 10px;
	color: #2b2b2b;
  } */

/* 
.ql-container {
    font-size: 16px !important;
} */


/* .collapse {
	overflow: hidden;
	transition: 0.4s max-height;
  }
  
  .collapse > div {
	overflow: auto;
  } */
  
  /* .sample-content {
	background: #ccc;
	padding: 10px;
  }
  
  h1, p {
	margin: 0;
	text-align: center;
  } */
  
  /* button {
	padding: 10px;
	display: block;
	background: #333;
	color: white;
	width: 100%;
	border: 0;
	cursor: pointer;
  } */
  
/* details {
	width: 60%;
   min-width: 350px;
   max-width: 900px;
   margin: 0 auto;
   
   height: var(--collapsed);
   overflow: hidden;
 
   transition: height 300ms cubic-bezier(0.4, 0.01, 0.165, 0.99);
  
   border-radius: 0.5em;
   background: #FFF;
 
   cursor: pointer;
 } */
 /* details[open] {
   height: var(--expanded);
 } */
 /* details[open] summary {
   background-color: #232323;
   color: #FFF;
 } */
 
 /* details p {
   padding: 1em;
 
   color: var(--black);
   background-color: #FFF;
 
   font-weight: 500;
   line-height: 1.6;
 
   cursor: auto;
 } */
 
 /* summary {
   padding: 0.6em 1em;
 
   font-size: 1.4rem;
 
   color: var(--black);
   background-color: var(--white);
 
   transition: all 0.3s;
 }
 summary span {
   font-size: medium;
   font-weight: 600;
 } */

 /* .ql-container {
	
	height: 380px !important;
 } */
